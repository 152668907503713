// eslint-disable-next-line import/no-cycle
import { decimateData } from './decimation.js';

export const MULTIPLIER = 10 ** 9;
export const AMOUNT_OF_DATA_ON_MOBILE = 20;
export const AMOUNT_OF_DATA_ON_TABLET = 7;

const feeFormatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 9,
    minimumFractionDigits: 0,
});

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
});

const currencyFormatterRound = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 0,
});

export const formatTons = function formatNanoTonsLimitNumberLength(value, decimals = 9, round = false) {
    const valueMultiplier = Number.isInteger(decimals)
        ? Math.pow(10, decimals) /* eslint prefer-exponentiation-operator: "off", no-restricted-properties: "off" */
        : MULTIPLIER;

    const currency = currencyFormatter.format(value / valueMultiplier);
    const fee = feeFormatter.format(value / valueMultiplier);

    const formattedCurrency = currency.replace(/,/g, '.');
    const formattedFee = fee.replace(/,/g, '.');

    return round
        ? formattedCurrency
        : formattedFee;

    // return round
    //     ? currencyFormatter.format(value / valueMultiplier)
    //     : feeFormatter.format(value / valueMultiplier);
};

export const formatFiat = function formatFiatValue(value, round = false) {
    const formatter = round ? currencyFormatterRound : currencyFormatter;
    return formatter.format(value).replace(/,/g, '.');
};

export const formatRoundNumber = function formatRoundNumberValue(value) {
    return currencyFormatterRound.format(value).replace(/,/g, '.');
};

export const formatDate = (dateValue) => {
    const date = new Date(Number(dateValue));
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};

// export const formatValidatorDate = (timestamp, locale) => {
//     const date = new Date(timestamp * 1000);

//     const dateFormat = new Intl.DateTimeFormat(locale, {
//         day: 'numeric',
//         month: 'short',
//         hour: '2-digit',
//         minute: '2-digit',
//     });

//     const formattedDate = dateFormat.format(date);

//     return `${formattedDate}`;
// };

export const formatValidatorDate = (timestamp, locale) => {
    const date = new Date(timestamp * 1000);

    const dateFormat = new Intl.DateTimeFormat(locale, {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
    });

    let formattedDate = dateFormat.format(date);

    if (locale.startsWith('ru')) {
        formattedDate = formattedDate.replace(/(\s[а-яё]+)\./i, '$1');
    }

    return formattedDate;
};

// export const getCurrencyFormatter = (locale, currency) => new Intl.NumberFormat(locale, { style: 'currency', currency });

export const getCurrencyFormatter = (locale, currency) => {
    const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency });
    const parts = formatter.formatToParts(0);
    const currencySymbol = parts.find(part => part.type === 'currency')?.value || '';

    return formattedValue => `${formattedValue} ${currencySymbol}`;
};

export const getCurrencyFormatterBelowZero = (locale, currency) => new Intl.NumberFormat(locale, { style: 'currency', currency, minimumSignificantDigits: 4, maximumSignificantDigits: 4 });

export const formatFee = formatTons;

export const decimateDataset = (dataset, offset) => ({ ...dataset, data: decimateData(dataset.data, offset) });

export const getDaysAgoTimestamp = (daysAgo) => {
    const now = Math.floor(Date.now() / 1000);
    return now - (daysAgo * 86400);
};

export const formatNumber = (number, decimals) => parseFloat(parseFloat(number).toFixed(decimals));

export const formatNumberCup = (number, decimalPlaces) => {
    if (typeof decimalPlaces !== 'number' || decimalPlaces < 0) {
        return '0';
    }

    const roundedString = number.toFixed(decimalPlaces);
    const roundedNumber = Number(roundedString);

    let formattedNumber = roundedNumber.toLocaleString('de-DE', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });

    formattedNumber = formattedNumber.replace(/\./g, ' ');

    return formattedNumber;
};

export const formatDecimalsUSDT = (value, decimals = 9) => {
    const formattedNumber = value / Math.pow(10, decimals);

    return parseFloat(formattedNumber.toFixed(decimals));
};

export const roundToDecimals = (inputNumber, threshold = 1, defaultDecimals = 2, smallValueDecimals = 6) => {
    const number = Number(inputNumber);

    if (Number.isNaN(number) || !Number.isFinite(number)) return 0;

    if (number === 0) return 0;

    const decimals = number < threshold ? smallValueDecimals : defaultDecimals;
    const factor = Math.pow(10, decimals);
    const result = Math.round(number * factor) / factor;

    if (Number.isInteger(result)) {
        return result.toFixed(defaultDecimals);
    }

    return result.toFixed(decimals);
};

export const shrinkAddress = (address) => {
    const ellipsis = '...';

    const visibleTextLength = Math.max(0, 20 - ellipsis.length);

    const partLength = Math.floor(visibleTextLength / 2);
    return address.slice(0, partLength) + ellipsis + address.slice(-partLength);
};

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;
};

export const formatDecimals = (value, decimals) => {
    const formattedNumber = value / Math.pow(10, decimals);

    return parseFloat(formattedNumber.toFixed(decimals));
};

export const formatSwap = (value, decimals) => {
    const price = formatDecimals(value, decimals);

    const numericPrice = parseFloat(price);

    let stringValue;

    if (numericPrice >= 0.1) {
        stringValue = numericPrice.toFixed(2);
    } else if (numericPrice > 0.01) {
        stringValue = numericPrice.toFixed(2);
    } else if (numericPrice > 0) {
        stringValue = numericPrice.toString();

        if (stringValue.includes('e-')) {
            const parts = stringValue.split('e-');
            const base = parts[0].replace('.', '');
            const exponent = parseInt(parts[1], 10);

            stringValue = '0.' + '0'.repeat(exponent - 1) + base;
        }

        const regex = /0\.(0*)([1-9])/;
        const match = stringValue.match(regex);
        if (match) {
            const leadingZeros = match[1];
            const firstNonZero = match[2];
            stringValue = `0.${leadingZeros}${firstNonZero}`;
        }
    } else {
        return '0.00';
    }

    const parts = stringValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const result = parts.join('.');

    return result.endsWith('.00') ? result.slice(0, -3) : result;

    // return parts.join('.');
};

export const formattedPrice = (value) => {
    const numericPrice = parseFloat(value);

    let stringValue;

    if (numericPrice >= 0.1) {
        stringValue = numericPrice.toFixed(2);
    } else if (numericPrice > 0.01) {
        stringValue = numericPrice.toFixed(2);
    } else if (numericPrice > 0) {
        stringValue = numericPrice.toString();

        if (stringValue.includes('e-')) {
            const parts = stringValue.split('e-');
            const base = parts[0].replace('.', '');
            const exponent = parseInt(parts[1], 10);

            stringValue = '0.' + '0'.repeat(exponent - 1) + base;
        }

        const regex = /0\.(0*)([1-9])/;
        const match = stringValue.match(regex);
        if (match) {
            const leadingZeros = match[1];
            const firstNonZero = match[2];
            stringValue = `0.${leadingZeros}${firstNonZero}`;
        }
    } else {
        return '0.00';
    }

    const parts = stringValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return parts.join('.');
};

export const formattedPriceChart = (value) => {
    const numericPrice = parseFloat(value);

    let stringValue;

    if (numericPrice >= 1) {
        stringValue = numericPrice.toFixed(2);
    } else if (numericPrice > 0) {
        stringValue = numericPrice.toString();

        if (stringValue.includes('e-')) {
            const parts = stringValue.split('e-');
            const base = parts[0].replace('.', '');
            const exponent = parseInt(parts[1], 10);

            stringValue = '0.' + '0'.repeat(exponent - 1) + base;
        }

        const regex = /0\.(0*)([1-9][0-9]+)/;
        const match = stringValue.match(regex);
        if (match) {
            const leadingZeros = match[1];
            const firstNonZero = match[2].slice(0, 3);
            stringValue = `0.${leadingZeros}${firstNonZero}`;
        } else {
            stringValue = '0.' + stringValue.split('.')[1];
        }
    } else {
        return '0.00';
    }

    const parts = stringValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return parts.join('.');
};
